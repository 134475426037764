var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('MyHeader',{attrs:{"title":_vm.$t('lang.menu_repair'),"RightIcon":"search"},on:{"rightclick":_vm.onRightClick}}),_c('van-tabs',{on:{"change":_vm.onChange},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('van-tab',{attrs:{"title":_vm.$t('lang.all')}}),_c('van-tab',{attrs:{"title":_vm.$t('lang.waitaudit')}}),_c('van-tab',{attrs:{"title":_vm.$t('lang.pending')}}),_c('van-tab',{attrs:{"title":_vm.$t('lang.processing')}}),_c('van-tab',{attrs:{"title":_vm.$t('lang.completed')}})],1),_c('van-list',_vm._l((_vm.lstData1),function(m){return _c('van-cell',{key:m.id,attrs:{"is-link":"","icon":m.reportType == 2 ? 'warning' : 'brush-o',"to":{
        name: m.reportType == 1 ? 'RepairDetail' : 'AbnormalDetail',
        query: {
          id: m.id,
        },
      }},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',[_vm._v(_vm._s(m.reportTime))]),_c('div',{staticStyle:{"color":"#646566"}},[_vm._v(_vm._s(m.routeName))]),_c('div',{staticStyle:{"color":"#646566"}},[_vm._v(_vm._s(m.placeName)+"-"+_vm._s(m.eventName))])]},proxy:true},{key:"right-icon",fn:function(){return [_c('div',[_c('span',[_vm._v(_vm._s(m.inspectorName))]),_c('br'),_c('br'),_c('van-tag',{attrs:{"color":m.state == 0
                ? '#ee0a24'
                : m.state == 1
                ? '#db08e2'
                : m.state == 2
                ? '#1989fa'
                : '#07c160',"size":"medium"}},[_vm._v(_vm._s(m.stateText))])],1)]},proxy:true}],null,true)})}),1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showHint),expression:"showHint"}],staticStyle:{"margin":"20px","text-align":"center","display":"block","color":"#969799"}},[_vm._v(" "+_vm._s(_vm.$t("lang.nodata"))+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }