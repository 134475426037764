<template>
  <div>
    <MyHeader
      :title="$t('lang.menu_repair')"
      RightIcon="search"
      @rightclick="onRightClick"
    />
    <van-tabs v-model="active" @change="onChange">
      <van-tab :title="$t('lang.all')"></van-tab>
      <van-tab :title="$t('lang.waitaudit')"></van-tab>
      <van-tab :title="$t('lang.pending')"></van-tab>
      <van-tab :title="$t('lang.processing')"></van-tab>
      <van-tab :title="$t('lang.completed')"></van-tab>
    </van-tabs>

    <van-list>
      <van-cell
        is-link
        v-for="m in lstData1"
        :icon="m.reportType == 2 ? 'warning' : 'brush-o'"
        :key="m.id"
        :to="{
          name: m.reportType == 1 ? 'RepairDetail' : 'AbnormalDetail',
          query: {
            id: m.id,
          },
        }"
      >
        <template #title>
          <div>{{ m.reportTime }}</div>
          <div style="color: #646566">{{ m.routeName }}</div>
          <div style="color: #646566">{{ m.placeName }}-{{ m.eventName }}</div>
        </template>
        <template #right-icon>
          <div>
            <span>{{ m.inspectorName }}</span>
            <br />
            <br />
            <van-tag
              :color="
                m.state == 0
                  ? '#ee0a24'
                  : m.state == 1
                  ? '#db08e2'
                  : m.state == 2
                  ? '#1989fa'
                  : '#07c160'
              "
              size="medium"
              >{{ m.stateText }}</van-tag
            >
          </div>
        </template>
      </van-cell>
    </van-list>
    <div
      v-show="showHint"
      style="margin: 20px; text-align: center; display: block; color: #969799"
    >
      {{ $t("lang.nodata") }}
    </div>
  </div>
</template>
<style scoped>
.van-icon-checked {
  color: #db08e2;
}
.van-icon-warning {
  color: #ff9900;
}
.van-icon-brush-o {
  color: red;
}
.custtag {
  height: 24px;
}
</style>
<script>
import MyHeader from "@/components/Header.vue";
import { GetRepairPreview } from "@/api/index.js";
import { getSessionObj } from "@/utils/session.js";

export default {
  components: { MyHeader },
  data() {
    return {
      lstData: [],
      lstData1: [],
      showHint: true,
      active: 0,
    };
  },

  activated() {
    let reload = this.$store.getters.getReload;
    if (this.$route.params.p || reload == 1) {
      if (reload == 0) this.active = 0;
      this.lstData = [];
      this.Query();
    }
  },

  deactivated() {
    //console.log("deactivated");
  },

  methods: {
    onRightClick() {
      this.$router.push({
        name: "QueryForm",
        params: { title: this.$t("lang.repair_query"), f: "RepairIndex" },
      });
    },

    onChange(index) {
      if (index == 0) this.lstData1 = this.lstData;
      else
        this.lstData1 = this.lstData.filter((d) => {
          return d.state == index - 1;
        });
      this.showHint = !(this.lstData1.length > 0);
    },

    Query() {
      let p = null;
      if (
        this.$route.params.p != undefined &&
        this.$route.params.p.pagesize != 1
      )
        p = this.$route.params.p;
      if (!p) p = getSessionObj("Query_Params");
      if (!p) {
        //this.showHint = true;
        return;
      }
      GetRepairPreview(
        {
          token: this.$store.getters.getToken,
          startTime: p.mDate1,
          endTime: p.mDate2,
          strCompanyIds: p.mCompany,
          strDepartmentIds: p.mDept,
          strRouteIds: p.mLine,
          userName: this.$store.getters.getUser.userName,
          reportType: p.mRepairType,
        },
        (ret) => {
          if (ret && ret.code == 0) {
            this.lstData = ret.data;
            this.$store.commit("setReload", 0);
            this.onChange(this.active);
          } else {
            this.$Toast(!ret ? this.$t("lang.queryfail") : ret.message);
          }
        }
      );
    },
  },
};
</script>